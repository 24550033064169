// Scroll to element on the page

export const useScrollTo = (querySelector, offset) => {
  const el = document.querySelector(querySelector);
  const y = el?.getBoundingClientRect().top - offset + window.scrollY;
  window.scroll({
    top: y,
    behavior: 'smooth',
  });
};
